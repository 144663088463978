.nav {
    background-color: #182ead;
    margin-bottom: 20px;
    
}

.nav-link {
    padding-top: 15px;
    padding-bottom: 15px;
}
    
.nav-link:hover {
    background-color:#4051b5;
}

.nav-item a {
    color: white;
}

.nav-item a:hover {
    color: white;
}